<template>
  <VContainer
    fluid
    class="pa-0 text-center"
  >
    <VRow
      justify="center"
    >
      <VCol
        cols="auto"
      >
        <TTBtn
          fab
          depressed
          color="white"
          elevation="1"
          large
          data-test="aside-right-groups-create"
          class="mb-2"
          @click="handleClickButton"
        >
          <VIcon
            color="tt-black"
            size="19"
          >
            {{ buttonIcon }}
          </VIcon>
        </TTBtn>
        <div class="tt-text-caption tt-black--text text--lighten-2">
          {{ buttonText }}
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>
<script>
export default {
  name: 'AppGroupsAsideRight',
  inject: ['Names'],
  computed: {
    buttonText() {
      if (!this.isManagement && !this.isCreate) return this.$route.params.id >= 0 ? 'Редактировать' : 'Создать';
      return 'Закрыть';
    },
    buttonIcon() {
      if (!this.isManagement && !this.isCreate) return this.$route.params.id >= 0 ? 'fal fa-pen' : 'fal fa-plus';
      return 'fal fa-times';
    },
    isManagement() {
      return this.$route.name === this.Names.R_GROUP_MANAGEMENT;
    },
    isCreate() {
      return this.$route.name === this.Names.R_GROUP_CREATE;
    },
  },
  methods: {
    handleClickButton() {
      if (!this.isManagement && !this.isCreate) {
        if (this.$route.params.id >= 0) {
          this.$root.$emit('click:group-edit');
        } else {
          this.$root.$emit('click:group-create');
        }
      } else {
        this.$root.$emit('click:group-close');
      }
    },
  },
};
</script>

<style scoped>
/* stylelint-ignore no-empty-source */
</style>
